import * as React from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Edit";
import MapIcon from "@mui/icons-material/Map";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@mui/material/TablePagination";
import ExcelDownload from "../../Excel/ExcelDownload";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputBase from "@mui/material/InputBase";
import L from "leaflet";
import redPinIcon from "../../../Assets/images/pin.png";
import Topbar from "../../global/Topbar";
import { fetchViewAdminClient } from "../../../Redux/Slice/Admin/Clients/ViewAdminClientsSlice";
import { addAdminClient } from "../../../Redux/Slice/Admin/Clients/AddClientSlice";
import { UpdateAdminClient } from "../../../Redux/Slice/Admin/Clients/UpdateClientSaga";
import { fetchViewUser } from "../../../Redux/Slice/Users/ViewUserSlice";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function AdminOther() {
  const dispatch = useDispatch();
  const ClientData = useSelector((state) => {
    // Retrieve the original data from the state
    const originalData = state.ViewAdminClients.ViewAdminClientData?.data;

    // Filter the data by category_id === "10"
    const filteredData = originalData?.filter(
      (client) => client.categoery_id === "10"
    );

    return filteredData;
  });
  const EmpData = useSelector((state) => state.ViewUser.ViewUserData?.data);
  const locationOnIcon = new L.Icon({
    iconUrl: redPinIcon,
    iconSize: [25, 30], // Adjust the size as needed
    iconAnchor: [15, 30],
    popupAnchor: [0, -30],
  });

  const loading = useSelector((state) => state.ViewAdminClients.isLoader);
  const error = useSelector((state) => state.ViewAdminClients.isError);
  const [selectedEmpCodes, setSelectedEmpCodes] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openModal, setOpenModal] = React.useState(false);
  const [downloadExcel, setdownloadExcel] = useState(false);
  const [excelData, setexcelData] = useState();

  // view list
  React.useEffect(() => {
    dispatch(fetchViewAdminClient());
    dispatch(fetchViewUser());
  }, [dispatch]);
  // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const disabledownload = () => {
    setdownloadExcel(false);
  };
  // add Other
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const [formData, setFormData] = useState({
    clientName: "",
    address: "",
    concered_person: "",
    phone: "",
    email: "",
    designation: "",
    categoery_id: "10",
    device_time: "",
    empCode: "",
  });
  const validateForm = () => {
    const errors = {};

    if (!formData.clientName.trim()) {
      errors.clientName = "Firm Name is required";
    }
    if (!formData.address.trim()) {
      errors.address = "Address is required";
    }
    if (!formData.concered_person.trim()) {
      errors.concered_person = "Concerned Person is required";
    }
    if (!formData.phone.trim()) {
      errors.phone = "Phone Number is required";
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    }
    if (!formData.designation.trim()) {
      errors.designation = "Designation is required";
    }
    if (!formData.empCode.trim()) {
      errors.empCode = "Emp Code is required";
    }

    return errors;
  };
  const [formErrors, setFormErrors] = useState({
    clientName: "",
    address: "",
    concered_person: "",
    phone: "",
    email: "",
    designation: "",
    device_time: "",
    empCode: "",
  });
  const handleForm = () => {
    const errors = validateForm();

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    const formDataWithDefaults = {
      ...formData,
      categoery_id: "10",
    };

    // Log the form data before dispatching
    console.log("Form Data:", formDataWithDefaults);

    dispatch(addAdminClient(formDataWithDefaults))
      .then(() => {
        // Reset form data and errors after successful addition
        setFormData({
          clientName: "",
          address: "",
          concered_person: "",
          phone: "",
          email: "",
          designation: "",
          device_time: "",
          empCode: "",
        });
        setFormErrors({});

        // Reload data after successful addition
        dispatch(fetchViewAdminClient());

        // Close the modal
        handleCloseModal();
      })
      .catch((error) => {
        console.error("Error adding Other:", error);
        toast.error("Error adding Other. Please try again.");
      });
  };

  const handleChange = (e) => {
    const currentDate = new Date();
    const formattedDate = currentDate
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      device_time: formattedDate,
    });
  };

  //   update
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState(null);
  const [editData, setEditData] = React.useState({
    id: "",
    clientName: "",
    address: "",
    concered_person: "",
    phone: "",
    email: "",
    designation: "",
    empCode: "",
    other_category: "",
    other: "",
  });

  const handleEditModalChange = (field, value) => {
    const currentDate = new Date();
    const formattedDate = currentDate
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    setEditData((prevData) => ({
      ...prevData,
      [field]: value,
      device_time: formattedDate, // Include device_time in the state update
    }));
  };

  const handleEdit = () => {
    const {
      id,
      clientName,
      address,
      concered_person,
      phone,
      email,
      designation,
      empCode,
      latitude,
      longitude,
      other,
      other_category,
    } = editData;

    const currentDate = new Date();
    const formattedDate = currentDate
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");

    const credentials = {
      id,
      clientName,
      address,
      concered_person,
      phone,
      email,
      designation,
      categoery_id: "10",
      device_time: formattedDate, // Use the PC's device time
      empCode,
      latitude,
      longitude,
      other,
      other_category,
    };

    dispatch(UpdateAdminClient(credentials))
      .then(() => {
        // Reload data after successful update
        dispatch(fetchViewAdminClient());

        // Close the edit modal
        handleCloseEditModal();
      })
      .catch((error) => {
        console.error("Error updating Other:", error);
        toast.error("Error updating Other. Please try again.");
      });
  };

  const handleOpenEditModal = (ClientData) => {
    if (ClientData) {
      setSelectedData(ClientData);
      setEditData({
        id: ClientData.id,
        clientName: ClientData.clientName,
        address: ClientData.address,
        concered_person: ClientData.concered_person,
        phone: ClientData.phone,
        email: ClientData.email,
        designation: ClientData.designation,
        empCode: ClientData.emp_code,
        latitude: ClientData.latitude,
        longitude: ClientData.longitude,
        other_category: ClientData.other_category,
        other: ClientData.other,
      });
    } else {
      setEditData({
        id: "",
        clientName: "",
        address: "",
        concered_person: "",
        phone: "",
        email: "",
        designation: "",
        categoery_id: "10",
        device_time: "",
        empCode: "",
        latitude: "",
        longitude: "",
        other: "",
        other_category: "",
      });
    }
    setOpenEditModal(true);
  };
  //   search
  const [searchTerm, setSearchTerm] = React.useState("");
  const handleSearch = (event) => {
    const newSearchTerm = event.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);

    // Update the page to the first one when the search term changes
    setPage(0);
  };

  const filteredData = ClientData?.filter(
    (client) =>
      (selectedEmpCodes.length === 0 ||
        selectedEmpCodes.includes(client?.emp_code)) &&
      (client.clientName.toLowerCase().includes(searchTerm) ||
        client.concered_person.toLowerCase().includes(searchTerm))
  );

  // Filter the data further based on role_id
  const filteredDataWithRole = filteredData?.filter(
    (ClientData) => ClientData.categoery_id === "10"
  );

  // Slice the data for pagination
  const slicedData = filteredDataWithRole?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  const [openMapModal, setOpenMapModal] = React.useState(false);
  const [mapLocation, setMapLocation] = React.useState({
    latitude: 0,
    longitude: 0,
  });

  const handleOpenMapModal = (ClientData) => {
    const latitude = parseFloat(ClientData.latitude);
    const longitude = parseFloat(ClientData.longitude);

    // Check if latitude and longitude are available
    if (isNaN(latitude) || isNaN(longitude)) {
      // Handle the case where latitude and/or longitude are not available
      toast.error("Latitude and/or Longitude not available for this Other.");
      return;
    }

    setMapLocation({
      latitude: latitude,
      longitude: longitude,
    });
    setOpenMapModal(true);
  };

  const handleCloseMapModal = () => {
    setOpenMapModal(false);
  };
  const [isOtherCategoryVisible, setIsOtherCategoryVisible] = useState(false);
  const [isOtherEditCategoryVisible, setIsOtherEditCategoryVisible] =
    useState(false);
  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Show/hide the "Other Type" TextField based on the selected value
    if (value === "Others") {
      setIsOtherCategoryVisible(true);
      // Reset the value of the "Other Type" TextField
      // setFormData({
      //   ...formData,
      //   other_category: "",
      // });
    } else {
      setIsOtherCategoryVisible(false);
    }
  };
  const handleEditSelectChange = (event) => {
    const { name, value } = event.target;
    setEditData({
      ...editData,
      [name]: value,
    });

    // Show/hide the "Other Type" TextField based on the selected value
    if (value === "Others") {
      setIsOtherEditCategoryVisible(true);
    } else {
      setIsOtherEditCategoryVisible(false);
    }
  };

  // all map
  const [openMapModalAll, setOpenMapModalAll] = React.useState(false);
  const [mapLocations, setMapLocations] = React.useState([]);

  const handleOpenMapModalAll = () => {
    // Filter the data by category_id === "10" and filter out invalid coordinates
    const filteredMapLocations = ClientData?.filter(
      (client) => client.categoery_id === "10"
    )
      .map((client) => ({
        latitude: parseFloat(client.latitude),
        longitude: parseFloat(client.longitude),
      }))
      .filter(
        (location) => !isNaN(location.latitude) && !isNaN(location.longitude)
      );

    setMapLocations(filteredMapLocations);
    setOpenMapModalAll(true);
  };

  const handleCloseMapModalAll = () => {
    setOpenMapModalAll(false);
  };

  const validMapLocations = mapLocations.filter(
    (location) => !isNaN(location.latitude) && !isNaN(location.longitude)
  );

  let mapBounds = null;
  if (validMapLocations.length > 0) {
    const latLngs = validMapLocations.map((location) =>
      L.latLng(location.latitude, location.longitude)
    );
    mapBounds = L.latLngBounds(latLngs);
  }
  const handleEmpCodeChange = (event, newValue) => {
    setSelectedEmpCodes(newValue);
  };
  const donloadexcel = () => {
    setdownloadExcel(true);
    const table = document.getElementById("exportTable");
    setexcelData(table);
  };
  return (
    <>
      <Topbar />
      <div style={{ padding: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "5px",
            background: "linear-gradient(to right, #4d0054, #91009e)",
            border: "1px solid #ddd", // Border color
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0)", // Box shadow with matching background color
            borderRadius: "5px", // Border radius
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#fff" }}
            startIcon={<AddIcon />}
            onClick={handleOpenModal}
          >
            Add Other
          </Button>

          <h4 style={{ color: "#ffffff" }}>Other Details</h4>
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              onClick={() => handleOpenMapModalAll()}
              sx={{ color: "#fff" }}
            >
              <MapIcon />
            </IconButton>{" "}
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={Array.from(
                new Set(
                  ClientData
                    ? ClientData.filter((client) => client && client.emp_code) // Filter out clients without user data or empCode
                        .map((client) => client.emp_code)
                    : []
                )
              )} // Provide an empty array if clientData is undefined
              disableCloseOnSelect
              onChange={handleEmpCodeChange}
              value={selectedEmpCodes}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox checked={selected} />
                  {option}
                </li>
              )}
              style={{ height: "33px" }}
              renderInput={(params) => (
                <Paper
                  component="form"
                  style={{ padding: "6px" }}
                  ref={params.InputProps.ref}
                >
                  <input
                    {...params.inputProps}
                    placeholder="Select User"
                    style={{ width: "100px" }}
                  />
                </Paper>
              )}
            />
            <InputBase
              sx={{ ml: 2, width: "150px" }}
              placeholder="Search by Firm or Concerned Person"
              onChange={handleSearch}
              style={{
                color: "#000",
                paddingLeft: "20px",
                padding: "3px",
                paddingRight: "5px",
                borderRadius: "5px",
                backgroundColor: "#fff", // Background color
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle box shadow
                outline: "none", // Remove outline on focus
                transition: "background-color 0.3s, box-shadow 0.3s", // Smooth transition effect
                marginRight: "10px",
              }}
            />
            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: "#fff" }}
              onClick={donloadexcel}
            >
              Download
            </Button>
          </div>
        </div>
        <ToastContainer position="bottom-right" autoClose={3000} />
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error.message}</div>
        ) : filteredDataWithRole && filteredDataWithRole.length > 0 ? (
          <React.Fragment>
            <TableContainer component={Paper}>
              <Table aria-label="customized table" id="exportTable">
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Sl. No.
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Emp Code
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Emp Name
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Firm Name
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Concerned Person
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Address
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Email
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Phone
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Designation
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Geotagged
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Action
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Map
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {slicedData?.map((ClientData, index) => (
                    <StyledTableRow key={ClientData.id}>
                      <StyledTableCell>
                        {index + 1 + page * rowsPerPage}
                      </StyledTableCell>
                      <StyledTableCell>
                        {ClientData.user?.empCode}
                      </StyledTableCell>{" "}
                      <StyledTableCell>{ClientData.user?.name}</StyledTableCell>
                      <StyledTableCell>{ClientData.clientName}</StyledTableCell>
                      <StyledTableCell>
                        {ClientData.concered_person}
                      </StyledTableCell>
                      <StyledTableCell>{ClientData.address}</StyledTableCell>
                      <StyledTableCell>{ClientData.email}</StyledTableCell>
                      <StyledTableCell>{ClientData.phone}</StyledTableCell>
                      <StyledTableCell>
                        {ClientData.designation}
                      </StyledTableCell>
                      <StyledTableCell>
                        {ClientData.latitude && ClientData.longitude
                          ? "Yes"
                          : "No"}
                      </StyledTableCell>
                      <StyledTableCell>
                        <IconButton
                          color="secondary"
                          onClick={() => handleOpenEditModal(ClientData)}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </StyledTableCell>
                      <StyledTableCell>
                        {ClientData.latitude && ClientData.longitude ? (
                          <IconButton
                            color="secondary"
                            onClick={() => handleOpenMapModal(ClientData)}
                          >
                            <MapIcon />
                          </IconButton>
                        ) : null}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, filteredDataWithRole.length]}
              component="div"
              count={filteredDataWithRole.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Rows per page"
            />
          </React.Fragment>
        ) : (
          <div style={{ padding: "20px" }}>No Other available</div>
        )}
        <Dialog open={openModal} maxWidth="md" onClose={handleCloseModal}>
          <DialogTitle
            sx={{ background: "linear-gradient(to right, #fff, #bfbfbf)" }}
          >
            Add Other
          </DialogTitle>
          <div
            style={{
              background:
                "url('https://img.freepik.com/premium-vector/flat-polygonal-background_23-2148918677.jpg?w=740')",
              backgroundSize: "cover",
            }}
          >
            <div style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <h5>Type</h5>
                    <FormControl fullWidth margin="normal">
                      <InputLabel htmlFor="other_category">Type</InputLabel>
                      <Select
                        label="other_category"
                        name="other_category"
                        id="other_category"
                        value={formData.other_category}
                        onChange={handleSelectChange}
                        error={!!formErrors.other_category}
                        sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                      >
                        <MenuItem value="Plumber">Plumber</MenuItem>
                        <MenuItem value="Service">Service</MenuItem>
                        <MenuItem value="Mason">Mason</MenuItem>
                        <MenuItem value="Others">Others</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {isOtherCategoryVisible && (
                    <Grid item xs={4}>
                      <h5>Other Type</h5>
                      <TextField
                        label="Other Type"
                        fullWidth
                        margin="normal"
                        name="other"
                        id="other"
                        value={formData.other}
                        onChange={handleChange}
                        error={!!formErrors.other}
                        helperText={formErrors.other}
                        sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={4}>
                    <h5>Firm Name</h5>
                    <TextField
                      label="Firm Name"
                      fullWidth
                      margin="normal"
                      name="clientName"
                      id="clientName"
                      value={formData.clientName}
                      onChange={handleChange}
                      error={!!formErrors.clientName}
                      helperText={formErrors.clientName}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <h5>Concerned Person</h5>
                    <TextField
                      label="Concerned Person"
                      fullWidth
                      margin="normal"
                      name="concered_person"
                      id="concered_person"
                      value={formData.concered_person}
                      onChange={handleChange}
                      error={!!formErrors.concered_person}
                      helperText={formErrors.concered_person}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <h5>Phone</h5>
                    <TextField
                      label="Phone"
                      fullWidth
                      margin="normal"
                      name="phone"
                      id="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      error={!!formErrors.phone}
                      helperText={formErrors.phone}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <h5>Email</h5>
                    <TextField
                      label="Email"
                      fullWidth
                      margin="normal"
                      name="email"
                      id="email"
                      value={formData.email}
                      onChange={handleChange}
                      error={!!formErrors.email}
                      helperText={formErrors.email}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <h5>Designation</h5>
                    <TextField
                      label="Designation"
                      fullWidth
                      margin="normal"
                      name="designation"
                      id="designation"
                      value={formData.designation}
                      onChange={handleChange}
                      error={!!formErrors.designation}
                      helperText={formErrors.designation}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>{" "}
                  <Grid item xs={4}>
                    <h5>Assigned To*</h5>
                    <FormControl fullWidth margin="normal">
                      <InputLabel htmlFor="role_id">Assigned To</InputLabel>
                      <Select
                        label="empCode"
                        name="empCode"
                        id="empCode"
                        value={formData.empCode}
                        onChange={handleChange}
                        error={!!formErrors.empCode}
                        helperText={formErrors.empCode}
                        sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                      >
                        {EmpData && EmpData ? (
                          EmpData.map((emp) => (
                            <MenuItem key={emp.id} value={emp.empCode}>
                              {emp.name} | Emp Code: {emp.empCode}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value="">Loading...</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <h5>Address</h5>
                    <TextField
                      label="Address"
                      fullWidth
                      multiline
                      rows={4} // Adjust the number of rows as needed
                      margin="normal"
                      name="address"
                      id="address"
                      value={formData.address}
                      onChange={handleChange}
                      error={!!formErrors.address}
                      helperText={formErrors.address}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
            </div>
          </div>

          <DialogActions
            sx={{ background: "linear-gradient(to right, #bfbfbf, #fff)" }}
          >
            <Button
              onClick={handleCloseModal}
              color="secondary"
              style={{
                background: "#fff",
                height: "100%",
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleForm}
              style={{
                background: "linear-gradient(to right, #2d0031, #4d0054)",
                height: "100%",
                color: "white",
              }}
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openEditModal} maxWidth="md" onClose={handleCloseModal}>
          <DialogTitle
            sx={{ background: "linear-gradient(to right, #fff, #bfbfbf)" }}
          >
            Update Other
          </DialogTitle>
          <div
            style={{
              background:
                "url('https://img.freepik.com/premium-vector/flat-polygonal-background_23-2148918677.jpg?w=740')",
              backgroundSize: "cover",
            }}
          >
            <div style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
              <DialogContent>
                <Grid container spacing={2}>
                  <input
                    type="hidden"
                    id="eid"
                    value={selectedData ? selectedData.id : ""}
                  />{" "}
                  <Grid item xs={4}>
                    <h5>Type</h5>
                    <FormControl fullWidth margin="normal">
                      <InputLabel htmlFor="other_category">Type</InputLabel>
                      <Select
                        label="other_category"
                        name="other_category"
                        id="other_category"
                        value={editData.other_category}
                        onChange={handleEditSelectChange}
                        error={!!formErrors.other_category}
                        sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                      >
                        <MenuItem value="Plumber">Plumber</MenuItem>
                        <MenuItem value="Service">Service</MenuItem>
                        <MenuItem value="Mason">Mason</MenuItem>
                        <MenuItem value="Others">Others</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {(isOtherEditCategoryVisible ||
                    editData.other_category === "Others") && (
                    <Grid item xs={4}>
                      <h5>Other Type</h5>
                      <TextField
                        label="Other Type"
                        fullWidth
                        margin="normal"
                        name="other"
                        id="other"
                        value={editData.other}
                        onChange={(e) =>
                          handleEditModalChange("other", e.target.value)
                        }
                        error={!!formErrors.other}
                        helperText={formErrors.other}
                        sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={4}>
                    <h5>Firm Name</h5>
                    <TextField
                      id="clientName"
                      label="Firm Name"
                      defaultValue={selectedData ? selectedData.clientName : ""}
                      onChange={(e) =>
                        handleEditModalChange("clientName", e.target.value)
                      }
                      fullWidth
                      margin="normal"
                      error={!!formErrors.clientName}
                      helperText={formErrors.clientName}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <h5>Concerned Person</h5>
                    <TextField
                      label="Concerned Person"
                      fullWidth
                      margin="normal"
                      name="concered_person"
                      id="concered_person"
                      defaultValue={
                        selectedData ? selectedData.concered_person : ""
                      }
                      onChange={(e) =>
                        handleEditModalChange("concered_person", e.target.value)
                      }
                      error={!!formErrors.concered_person}
                      helperText={formErrors.concered_person}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <h5>Phone</h5>
                    <TextField
                      label="Phone"
                      fullWidth
                      margin="normal"
                      name="phone"
                      id="phone"
                      defaultValue={selectedData ? selectedData.phone : ""}
                      onChange={(e) =>
                        handleEditModalChange("phone", e.target.value)
                      }
                      error={!!formErrors.phone}
                      helperText={formErrors.phone}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <h5>Email</h5>
                    <TextField
                      label="Email"
                      fullWidth
                      margin="normal"
                      name="email"
                      id="email"
                      defaultValue={selectedData ? selectedData.email : ""}
                      onChange={(e) =>
                        handleEditModalChange("email", e.target.value)
                      }
                      error={!!formErrors.email}
                      helperText={formErrors.email}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <h5>Designation</h5>
                    <TextField
                      label="Designation"
                      fullWidth
                      margin="normal"
                      name="designation"
                      id="designation"
                      defaultValue={
                        selectedData ? selectedData.designation : ""
                      }
                      onChange={(e) =>
                        handleEditModalChange("designation", e.target.value)
                      }
                      error={!!formErrors.designation}
                      helperText={formErrors.designation}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>{" "}
                  <Grid item xs={4}>
                    <h5>Assign To*</h5>
                    <FormControl fullWidth margin="normal">
                      <InputLabel htmlFor="role_id">Select Name</InputLabel>
                      <Select
                        label="empCode"
                        name="empCode"
                        id="empCode"
                        value={editData.empCode}
                        onChange={(e) => {
                          setEditData({
                            ...editData,
                            empCode: e.target.value,
                          });
                        }}
                        sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                      >
                        {EmpData && EmpData ? (
                          EmpData.map((emp) => (
                            <MenuItem key={emp.id} value={emp.empCode}>
                              {emp.name} | Emp Code: {emp.empCode}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value="">Loading...</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <h5>Address</h5>
                    <TextField
                      label="Address"
                      fullWidth
                      multiline
                      rows={4} // Adjust the number of rows as needed
                      margin="normal"
                      name="address"
                      id="address"
                      defaultValue={selectedData ? selectedData.address : ""}
                      onChange={(e) =>
                        handleEditModalChange("address", e.target.value)
                      }
                      error={!!formErrors.address}
                      helperText={formErrors.address}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
            </div>
          </div>
          <DialogActions
            sx={{ background: "linear-gradient(to right, #bfbfbf, #fff)" }}
          >
            <Button
              onClick={handleCloseEditModal}
              color="secondary"
              style={{
                background: "#fff",
                height: "100%",
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleEdit}
              style={{
                background: "linear-gradient(to right, #2d0031, #4d0054)",
                height: "100%",
                color: "white",
              }}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openMapModal} onClose={handleCloseMapModal}>
          <DialogTitle>Location on Map</DialogTitle>
          <DialogContent>
            <MapContainer
              center={[mapLocation.latitude, mapLocation.longitude]}
              zoom={13}
              style={{ height: "400px", width: "400px" }}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <Marker
                position={[mapLocation.latitude, mapLocation.longitude]}
                icon={locationOnIcon}
              >
                {/* <Popup>
                Location: {mapLocation.latitude}, {mapLocation.longitude}
              </Popup> */}
              </Marker>
            </MapContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseMapModal} color="secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openMapModalAll} onClose={handleCloseMapModalAll}>
          <DialogTitle>Location on Map</DialogTitle>
          <DialogContent>
            <MapContainer
              bounds={mapBounds}
              style={{ height: "400px", width: "400px" }}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              {ClientData?.map((client) => {
                const latitude = parseFloat(client.latitude);
                const longitude = parseFloat(client.longitude);
                if (isNaN(latitude) || isNaN(longitude)) {
                  // Skip markers with invalid latitude or longitude
                  return null;
                }
                return (
                  <Marker
                    key={client.id}
                    position={[latitude, longitude]}
                    icon={locationOnIcon}
                  >
                    <Popup>{client.clientName}</Popup>
                  </Marker>
                );
              })}
            </MapContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseMapModalAll} color="secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div style={{ display: downloadExcel ? "block" : "none" }}>
        {downloadExcel ? (
          <ExcelDownload
            data={excelData}
            name="Other-data"
            onsubmit={disabledownload}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
